import React, { RefObject} from 'react'
import tw, { classnames, padding, margin } from 'tailwindcss-classnames'
import { Section, About } from '../'

import { sectionMargin } from '../../lib/tailwind/styles'

type Props = {
    niceToMeetText: string,
    aboutRef: RefObject<HTMLDivElement | null>
}

const AboutSection = ({niceToMeetText, aboutRef}: Props ) => {
    return (
        <Section sectionRef={aboutRef} key="about" className={`about-section 2xl:max-w-screen-xl1/2 ${
            classnames(sectionMargin, padding("md:px-4", "xl:px-8"), margin("2xl:mx-auto"))
        }`}>
            <About niceToMeetText={niceToMeetText} />
        </Section>
    )
}

export default AboutSection