
import tw, { classnames, TArg, margin, display, flexDirection } from 'tailwindcss-classnames'
import {h2} from '../../lib/tailwind/styles'
import { HeaderDeco} from '../Icons'

type Props = {
    title: string,
    decoColor?: string,
    className?: TArg
}

const H2 = ({title, decoColor, className}: Props) => {
    return (
        <div className={classnames(className, margin("mb-4", "lg:mb-6"), display("flex"), flexDirection("flex-col"))} >
            <h2 className={classnames(h2, margin("mb-2"))}>{title}</h2>
            <HeaderDeco fill={decoColor} className="h-1 w-auto lg:h-auto" />
        </div>

    )
}

export default H2