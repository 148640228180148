import React, { RefObject, CSSProperties, JSX } from 'react'

type SectionProps = {
    sectionRef?: RefObject<HTMLDivElement | null>,
    className?: string, 
    children?: JSX.Element | JSX.Element[] | string,
    style?: CSSProperties
}

const Section = ({sectionRef, className, children, style}: SectionProps) => (
    
    <div ref={sectionRef} className={`container relative max-w-none ${className}`} style={style}>
        {children}
    </div>
)

export default Section;