import React, { RefObject, useState} from "react";
import dynamic from 'next/dynamic'
import tw, { classnames, padding, margin, alignItems } from "tailwindcss-classnames";

import { Review } from 'src/packages/domain'
import { Section, H2 } from '..'
import { sectionMargin} from '../../lib/tailwind/styles'

import useScrollPosition from '../../lib/useScrollPostition'
const ReviewCarousel = dynamic(()=>import('../Review/ReviewCarousel'))
const ReviewFlex = dynamic(()=>import('../Review/ReviewsFlex'))

const SHOW_CAROUSEL_THRESHOLD = 1500


type Props = {
    reviews?: Review[],
    reviewsRef: RefObject<HTMLDivElement | null>
}

const ReviewsSection = ({reviews, reviewsRef}: Props ) => {

    const scrollPosition = useScrollPosition().top
    const showAsCarousel = (scrollPosition > SHOW_CAROUSEL_THRESHOLD)
        ?   true
        :   false

    return (
        <Section sectionRef={reviewsRef} key="reviews" className={`2xl:max-w-screen-xl1/2 ${classnames(sectionMargin, padding("px-0", "sm:px-3", "md:px-4", "xl:px-8", "md:px-4"), margin("2xl:mx-auto"))}`} >
            <H2 className={classnames(alignItems("items-center"), padding("pb-2"))} title="חוויות מסדנאות" />
            <>
                {showAsCarousel && 
                    <ReviewCarousel reviews={reviews} />
                
                }
                {!showAsCarousel && 
                    <ReviewFlex reviews={reviews} />
                }
            </>
    </Section>        
    )
}

export default ReviewsSection;