
import tw, { classnames, padding, margin, fontSize, lineHeight, fontWeight, display, justifyContent, alignItems, width, height, borderWidth, borderRadius, backgroundColor, borderColor, textColor } from "tailwindcss-classnames"
export const pagePadding = classnames(padding("px-5", "sm:px-6", "md:px-8", "xl:px-16"))
export const sectionMargin = classnames(margin("mt-20", "md:mt-20", "lg:mt-32", "xl:mt-40", "2xl:mt-48"))
export const h2 = classnames(fontSize("text-2xl", "sm:text-3xl", "lg:text-4xl"), lineHeight("leading-8", "sm:leading-9", "lg:leading-10"), fontWeight("font-bold"))
export const h3 = classnames(fontSize("text-lg", "sm:text-xl", "lg:text-2xl"), lineHeight("leading-5", "sm:leading-6", "md:leading-7", "lg:leading-8", 'xl:leading-9', "2xl:leading-10"), fontWeight("font-bold"))
export const button = classnames(display("flex"), justifyContent("justify-center"), alignItems("items-center"), width("w-32", "sm:w-40"), height("h-9", "sm:h-11"), borderWidth("border-2"), padding("px-1", "py-1"), borderRadius("rounded-3xl"), fontWeight("font-medium"), lineHeight("leading-none"))
export const buttonOrange = classnames(button, backgroundColor("bg-yellow-400", "hover:bg-yellow-500"), borderColor("border-yellow-400", "hover:border-yellow-500"), textColor("text-white"))
export const buttonWhite = classnames(button, backgroundColor("bg-white"))
export const buttonWhiteOrange = classnames(buttonWhite, borderColor("border-yellow-400"), textColor("text-yellow-400", "hover:text-white"), backgroundColor("hover:bg-yellow-400"))
export const buttonWhiteBlue = classnames(buttonWhite, borderColor("border-blue-400"), textColor("text-blue-400"))
