import React, { RefObject } from "react";
import tw, { classnames, padding, maxWidth, margin, width, alignItems } from "tailwindcss-classnames";

import { Post} from 'src/packages/domain'
import { Section, PostItem, H2 } from "..";
import {buttonOrange, sectionMargin} from '../../lib/tailwind/styles'


const BlogSection = ({posts, blogRef}: {posts: Post[], blogRef: RefObject<HTMLDivElement | null>}) => {
    return (
        <Section className={`blog-section w-379 md:max-w-880 2xl:max-w-screen-xl1/2 2xl:mx-auto ${classnames(sectionMargin, padding("px-5", "sm:px-3", "xl:px-8"), maxWidth("max-w-full", "sm:max-w-2xl", "lg:max-w-full"), margin("mx-auto"), width("sm:w-full"))}`} sectionRef={blogRef} key="blog">
            <H2 className={classnames(alignItems("items-center"), margin("mb-6"))} title="פשוט ליצור" />
            {posts && 
                <div className="flex flex-wrap">
                    { posts.map((post, key) => <PostItem key={key} {...post} />)}
                </div>
            }
        </Section>
    )
}

export default BlogSection