import React, { RefObject} from 'react'
import { Wellcome } from '../'
import { Section } from '../'

type Props = {
    wellcomeText: string,
    workshopsRef: RefObject<HTMLDivElement | null>,
    therapyRef: RefObject<HTMLDivElement | null>,
}

const WellcomeSection = ({wellcomeText, workshopsRef, therapyRef}: Props) => {
    return (
        <Section key="wellcome" className="">
            <Wellcome {...{wellcomeText, workshopsRef, therapyRef}} />
        </Section>
    )
}

export default WellcomeSection