import React, { RefObject} from "react";
import dynamic from "next/dynamic";
import tw, { classnames, padding, margin, alignItems } from "tailwindcss-classnames";

import { Image as ImageInf } from 'src/packages/domain';
import { Section, H2 } from "..";

import { sectionMargin} from '../../lib/tailwind/styles'

import useScrollPosition from "../../lib/useScrollPostition";

const Gallery = dynamic(()=>import('../Gallery/Gallery'))
const GalleryCarousel = dynamic(()=>import('../Gallery/GalleryCarousel'))

const SHOW_CAROUSEL_TRESHOLD = 1500

type SectionProps = {
    gallery: ImageInf[]
} 


type Props = SectionProps & {
    galleryRef: RefObject<HTMLDivElement | null>
}

const GallerySection = ({gallery, galleryRef}: Props ) => {

    const scrollPosition = useScrollPosition().top

    const showAsCarousel = scrollPosition && scrollPosition > SHOW_CAROUSEL_TRESHOLD

    return (
        <Section 
            sectionRef={galleryRef} 
            key="gallery" 
            className={`gallery-section 2xl:max-w-screen-xl1/2 carousel-items-center ${classnames(sectionMargin, padding("md:px-8", "xl:px-24"), margin("2xl:mx-auto"))}`}
        >
            <div className="lg:mb-10">
                <H2 key="title" className={classnames(alignItems("items-center"), margin("mb-8"))} title="גלריה" />
            </div>
            <>
                { showAsCarousel && 
                    <GalleryCarousel key='galleryCarousel' gallery={gallery} />
                }
                {!showAsCarousel &&
                    <Gallery key="gallery" gallery={gallery} />
                }
            </>
        </Section>
    )
}

export default GallerySection