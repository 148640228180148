import { Post} from 'src/packages/lagaat-domain'
import Image from 'next/image'
import tw, { classnames, padding, margin } from 'tailwindcss-classnames'
import { h3 } from '../../lib/tailwind/styles'

const PostItem = (post: Post) => {
    const { thumbnail, title, excerpt, link} = post
    return (
        <div className='mt-6 sm:w-3/6 sm:px-3 md:px-6 xl:px-8 lg:w-2/6'>
            {thumbnail && 
                <div className="flex justify-center">
                    <Image
                        alt={thumbnail.alt || "deco"}
                        {...thumbnail} 
                    />
                </div>
            }
            { title &&
                <h3 className={`-mb-1 ${classnames(
                    h3, 
                    padding("py-4"), 
                    margin("mt-0")
                )}`}>{title}</h3>
            }
            {excerpt && 
                <p>{excerpt}</p>
            }
            <a target="_blank" rel="noreferrer" href={link ? link  : "#"} className="font-bold mt-2 mb-6 hover:text-orange-400">{"קרא.י עוד >"}</a>
        </div>
    )
}

export default PostItem