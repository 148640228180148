import { MouseEvent, RefObject } from 'react'


const scrollToRef = (linkRef: RefObject<HTMLDivElement | null>) => (e: MouseEvent) => {

    if (!linkRef.current) {
        return
    }

    e.preventDefault()

    linkRef.current?.scrollIntoView({
        behavior: "smooth"
    })
}

export default scrollToRef