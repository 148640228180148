import React, { RefObject } from 'react'
import Image from 'next/image'
import tw, { classnames, fontWeight, fontSize, lineHeight, maxWidth, width, position, inset, translate, zIndex, textColor, textAlign, padding } from 'tailwindcss-classnames'

import reutImg from '../../../public/reut.png'

import {buttonOrange, buttonWhiteOrange} from '../../lib/tailwind/styles'

import scrollToRef from '../../lib/scrollToRef'

type WellcomeProps = {
    wellcomeText: string,
    workshopsRef: RefObject<HTMLDivElement | null>,
    therapyRef: RefObject<HTMLDivElement | null>,
}

const WellcomeText = ({wellcomeText}: {wellcomeText: string}) => (
        <div 
            className={classnames(fontWeight("font-bold"), fontSize("text-2xl", "sm:text-3xl", "md:text-4xl", "lg:text-5xl"), lineHeight("leading-7", "sm:leading-9"))}
            dangerouslySetInnerHTML={{__html: wellcomeText}}
        />
)



const Wellcome = ({wellcomeText, therapyRef, workshopsRef}: WellcomeProps) => {

    const Buttons = () => (
        <div className='flex gap-x-6 sm:gap-x-9 md:gap-10 lg:gap-12 xl:gap-16 2xl:gap-20 justify-center mt-5 lg:mt-8 xl:mt-10 2xl:mt-12'>
            <a onClick={scrollToRef(therapyRef)} className={classnames(buttonOrange)} href='#therapy' title='תרפיה באומנות'>תרפיה באומנות</a>
            <a onClick={scrollToRef(workshopsRef)} className={classnames(buttonWhiteOrange)} href='#workshops' title="סדנאות">סדנאות</a>
        </div>
    )

    return <div key="wellcome" className="flex relative max-w-none bg-blue-400">
        <div key="me" className="h-96 md:h-4/10vw w-full">
            <Image src={reutImg} layout="fill" objectFit="cover" alt="me" priority={true} quality={60} />
        </div>
        <div className={`xl:max-w-978 -translate-y-1/2 ${classnames(maxWidth("max-w-4xl", "md:max-w-2xl"), width("md:w-full"), position("absolute"), inset("top-1/2", "md:right-1/2"), translate("md:translate-x-1/2"), zIndex("z-10"), textColor("text-white"), lineHeight("leading-tight"), textAlign("text-center"), padding("p-6"))}`}>
            <WellcomeText key="text" wellcomeText={wellcomeText} />
            <Buttons key='buttons' />
        </div>
    </div>   

}

export default Wellcome