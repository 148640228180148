import { RefObject } from "react"

export enum Sections  {
    therpay = 'therapy',
    workshops = 'workshops',
    blog = 'blog',
    gallery = 'gallery',
    about = 'about',
    reviews = 'reviews',
    contact = 'contact',
}

type SectionsRefs = {
    [Property in Sections]: RefObject<HTMLDivElement | null>
}

export type {
    SectionsRefs
}