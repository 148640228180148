import React, { RefObject} from 'react'
import tw, { classnames } from 'tailwindcss-classnames'
import { Section, Therapy } from '../'

import { TherapyOption } from 'src/packages/lagaat-domain'

import { sectionMargin} from '../../lib/tailwind/styles'

type Props = TherapyOption & {
    therapyRef: RefObject<HTMLDivElement | null>,
    contactRef: RefObject<HTMLDivElement | null>,
}

const TheapySection = ({therapyRef, contactRef, ...therapyOptions}: Props) => {
    
    return (
        <Section sectionRef={therapyRef} key="therapy" className={classnames(sectionMargin)}>
            <Therapy {...therapyOptions} contactRef={contactRef} />
        </Section>
    )
}

export default TheapySection