import Image from 'next/image'
import tw, { classnames, padding, backgroundColor, textColor, width, alignItems, textAlign, alignSelf, maxWidth } from 'tailwindcss-classnames'

import { H2 } from '..'
import useReponsiveLayout from '../../lib/tailwind/useResponsiveLayout'
import reutImg from '../../../public/reut-round.png'

const About = ({niceToMeetText}: {niceToMeetText: string}) => {
    const {isMd} = useReponsiveLayout()
    return (
        <div id='about-me' className="flex flex-col md:flex-row gap-8 md:gap-0 md:items-center md:justify-between">
            <div className={classnames(padding("px-5", "sm:px-6", "md:px-4", "xl:px-8", "py-8"), backgroundColor("bg-blue-400", "md:bg-white"), textColor("text-white", "md:text-black"), width("md:w-1/2"))}>
                <H2 className={classnames(alignItems("items-start"))} title="נעים להכיר" decoColor={isMd ? "black" : "white"}/>
                {niceToMeetText &&
                    <div className="scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 pl-4 prose text-white md:text-black max-h-52 mt-8 lg:mt-12 overflow-y-scroll">
                        <div className="pl-8" dangerouslySetInnerHTML={{__html: niceToMeetText}} />
                    </div>
                }
            </div>
            <div className={classnames(padding("px-5", "sm:px-6", "md:px-4", "xl:px-8"), textAlign("text-center", "md:text-left"), alignSelf("self-center", "lg:self-end"), width("md:w-1/2"), maxWidth("max-w-md"))}>
                <Image src={reutImg} width={516} height={505} alt='reut' />
            </div>

        </div>
    )
}

export default About