import React, { RefObject} from 'react'
import tw, { classnames, padding, backgroundColor, textColor, width, alignItems, margin, position, overflow, height } from 'tailwindcss-classnames'
import Image from 'next/image'

import { TherapyOption } from 'src/packages/lagaat-domain'
import { H2 } from '../'
import {pagePadding, buttonWhiteBlue} from '../../lib/tailwind/styles'

import scrollToRef from '../../lib/scrollToRef'

type Props = TherapyOption & {
    contactRef: RefObject<HTMLDivElement | null>,
}

const Therapy = ({contactRef, ...therapyOptions}: Props ) => {
    const {image, title, text, buttonShowText, moreText, price, duration} = therapyOptions

    return (
        <div className="md:flex">
            <div className={`2xl:h-564 ${classnames(pagePadding, padding("py-5", "lg:pt-10", "xl:pt-16"), backgroundColor("bg-blue-400"), textColor("text-white"), width("md:w-1/2"))}`}>
                <div className="2xl:max-w-650 2xl:mr-auto 2xl:pr-16 2xl:pl-28" >
                    <H2 className={classnames(alignItems("items-start"), margin("mb-8", "2xl:mb-10", "mt-3"))} title={title} decoColor="white" />
                    <div className={`h-48 pl-8 lg:h-56 scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200`}>
                        <p>{text}</p>
                        <p>{moreText}</p> 
                    </div>
                    <a 
                        href="#" 
                        title='פרטים נוספים' 
                        onClick={scrollToRef(contactRef)}
                        className={classnames(buttonWhiteBlue, margin("mt-8", "2xl:mt-10", "xl:mt-10", "2xl:mt-12", "mb-4", "2xl:mb-16"))} 
                    >
                        {buttonShowText}
                    </a>
                </div>
            </div>
            <div className={`xl:h-564 ${classnames(position("relative"), overflow("overflow-hidden"), width("md:w-1/2"), height("h-60", "md:h-auto"))}`}>
                <Image alt={image.alt || "תרפיה-באוממנות"} src={encodeURI(image.src)} layout="fill" objectFit="cover" />
            </div>

        </div>
    )
}

export default Therapy