
import React, {Fragment } from 'react'
import Head from 'next/head';
import dynamic from 'next/dynamic'

import { 
    Image,
    Post, 
    Review, 
    
} from 'src/packages/domain';

import { 
    Workshop, 
    TherapyOption,
    FooterOption,
    Menu,
    Sections
} from 'src/packages/lagaat-domain'

import { 
    PostsRepoImp, 
    ReviewsRepoImp,
    OptionsRepoImp,
    ImageRepoImp
} from 'src/packages/markdown';

import {
    Header,
    useSectionsRef,
    WellcomeSection,
    BlogSection,
    ReviewsSection,
    GallerySection,
    WorkshopSection,
    TheapySection,
    AboutSection
} from '../components'


import { 
    WORKSHOP_ROOT_DIR,
    OPTION_WELLCOME_TEXT,
    OPTION_THERAPY_SECTION,
    OPTION_NICE_TO_MEET_TEXT,
    GALLERY_ROOT_DIR,
    OPTION_FOOTER,
    OPTION_MENU,
 } from '../app'

 const Footer = dynamic(()=>import('../components/Footer'))

type PropsType = {
    workshops: Workshop[]
    posts: Post[],
    reviews: Review[],
    wellcomeText: string
    therapyOption: TherapyOption,
    niceToMeetText: string,
    gallery: Image[],
    footerOption: FooterOption,
    menu: Menu
}

const PageIndex = (props: PropsType) => {
    
    const { 
        workshops, 
        gallery, 
        reviews, 
        wellcomeText, 
        therapyOption, 
        niceToMeetText, 
        posts, 
        footerOption,
        menu
    } = props

    const sectionsRefs = useSectionsRef()
    menu && Object.values(Sections).map((name) => {
        menu[name].linkRef = sectionsRefs[name]
    })

    return (
        <Fragment>
            <Head>
                <title>לגעת בחומר</title>
                <meta key='description' name="description" content="תרפיה באומנות,סטודיו לגעת בחומר, רעות רביץ רז" />
            </Head>
            <Header key="header" menu={menu} />
            <div key='content'>
                <WellcomeSection key="wellcome" wellcomeText={wellcomeText} workshopsRef={sectionsRefs.workshops} therapyRef={sectionsRefs.therapy} />
                <TheapySection 
                    therapyRef={sectionsRefs.therapy} 
                    key="therpay" 
                    {...therapyOption} 
                    contactRef={sectionsRefs.contact} 
                />
                <WorkshopSection workshopsRef={sectionsRefs.workshops} key="workshops" workshops={workshops} contactRef={sectionsRefs.contact} />
                <BlogSection blogRef={sectionsRefs.blog} key='blog' posts={posts} />
                <GallerySection galleryRef={sectionsRefs.gallery} key="gallery" gallery={gallery} />
                <AboutSection aboutRef={sectionsRefs.about} key='about' niceToMeetText={niceToMeetText} />
                <ReviewsSection reviewsRef={sectionsRefs.reviews} key="reviews" reviews={reviews} />

            </div>     
            <Footer contactRef={sectionsRefs.contact} key='footer' {...footerOption} />

        </Fragment>
    )
}

export default PageIndex;

export async function getStaticProps() {

    const ReviewsRep = new ReviewsRepoImp()
    const WorkshopRepo = new PostsRepoImp(undefined, WORKSHOP_ROOT_DIR)
    const PostsRepo = new PostsRepoImp()
    const OptionsRepo = new OptionsRepoImp()

    const workshopsResponse = await WorkshopRepo.allEntities()
    const workshops: Workshop[] = workshopsResponse.map(post => {
        return {
            duration: "",
            price: "",
            ...post
        }
    })

    console.log('workshops', workshops)

    const posts = await PostsRepo.allEntities()


    const reviews = await ReviewsRep.allEntities()
    const wellcomeText = await (await OptionsRepo.entityByName(OPTION_WELLCOME_TEXT)).content
    const therapyOption = await (await OptionsRepo.entityByName(OPTION_THERAPY_SECTION)).value
    const niceToMeetText = await (await OptionsRepo.entityByName(OPTION_NICE_TO_MEET_TEXT)).content

    const ImageRepo = new ImageRepoImp()
    const gallery = await ImageRepo.allImages(GALLERY_ROOT_DIR)
    const footerOption = await (await OptionsRepo.entityByName(OPTION_FOOTER)).value
    const menu = await (await OptionsRepo.entityByName(OPTION_MENU)).value

    return {
        props: {
            posts,
            workshops,
            gallery,
            reviews,
            wellcomeText,
            therapyOption,
            niceToMeetText,
            footerOption,
            menu
        }
    }
}